import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { HeaderContext } from 'app/containers/Header'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo, useEffect, useRef, useState } from 'react'
import { useContext } from 'react'

import { LanguageItem, Props as LanguageItemProps } from './LanguageItem'

export interface Props {
  className?: string
  isSticky?: boolean
  languagesList: LanguageItemProps[]
  pageID?: string
}

export const Languages = memo(function Languages({
  isSticky,
  languagesList,
  pageID,
}: Props) {
  const { languagePrefix } = useContext(HeaderContext) as any

  const languages = languagesList.filter((t) => t.pageID === pageID)

  const activeLanguage = useRef(null)
  const [othersLanguagesStatus, setOthersLanguagesStatus] = useState(false)

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (activeLanguage.current && !activeLanguage.current.contains(e.target)) {
      setOthersLanguagesStatus(false)
    } else {
      // @ts-ignore
      if (othersLanguagesStatus) {
        setOthersLanguagesStatus(false)
      } else {
        setOthersLanguagesStatus(true)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [othersLanguagesStatus])

  return (
    <Container className={isSticky ? 'sticky' : undefined} ref={activeLanguage}>
      <LanguageActive className={othersLanguagesStatus ? 'open' : undefined}>
        {languages
          .filter((t) => t.languagePrefix === languagePrefix)
          .map((item, index) => (
            <LanguageItem className="active" key={index} {...item} />
          ))}
      </LanguageActive>
      <OthersLanguages className={othersLanguagesStatus ? 'open' : undefined}>
        {languagesList
          .filter((t) => t.languagePrefix !== languagePrefix)
          .map((item, index) => (
            <LanguageItem key={index} {...item} />
          ))}
      </OthersLanguages>
    </Container>
  )
})

const Sticky = css`
  a {
    color: ${theme.colors.variants.neutralDark4} !important;

    svg {
      stroke: ${theme.colors.variants.neutralDark4};
    }
  }

  div {
    &:last-of-type {
      background: ${theme.colors.variants.primaryLight};
      padding: 0.75rem 0;
      top: 3.5rem;
      right: auto;
      left: 50%;
      transform: translate(-50%, -1.25rem);
      &.open {
        transform: translate(-50%, 0);
      }

      a {
        display: block;
        color: ${theme.colors.variants.neutralLight4} !important;
        padding: 0 1.5rem;
      }

      @media (max-width: 1199px) {
        top: 2.5rem;
      }
    }
  }
`

const Container = styled.div`
  margin-right: auto;
  margin-left: 1.875rem;
  padding-left: 1.875rem;
  position: relative;
  z-index: 3;
  border-left: 0.0625rem solid ${rgba(theme.colors.variants.neutralLight4, 0.3)};
  &.sticky {
    ${Sticky}
  }

  @media (max-width: 1199px) {
    ${Sticky}
    border-left: none;
    margin-left: auto;
    margin-right: 2.875rem;
    padding-left: 0;
  }
`

const LanguageActive = styled.div`
  cursor: pointer;
  position: relative;
  transition: 0.3s ease-in-out;
  z-index: 3;
  &.open {
    svg {
      transform: scaleY(-1);
    }
  }
`

const OthersLanguages = styled.div`
  opacity: 0;
  position: absolute;
  top: 2.5rem;
  left: 0;
  transform: translateY(1.25rem);
  transition: 0.4s ease-in-out;
  visibility: hidden;
  z-index: -1;
  &.open {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    z-index: 2;
  }

  a {
    margin-top: 0.75rem;
    &:first-of-type {
      margin-top: 0;
    }
  }
`
