import styled from '@emotion/styled'
import { Checkbox } from 'app/components/Common/Checkbox'
import { FormMessages } from 'app/components/Common/FormMessages'
import { Input } from 'app/components/Common/Input'
import { Spinner } from 'app/components/Common/Spinner'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { FormData, NewsletterFormSender } from 'app/utils/NewsletterFormSender'
import { useVocabularyData } from 'app/utils/vocabulary'
import { isLeft } from 'fp-ts/lib/These'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

const EMAIL_REGEX = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i

export interface Props {
  languageCode: string
  onNewsletterFormSendTo: NewsletterFormSender
}

export const Newsletter = memo(function Newsletter({
  languageCode,
  onNewsletterFormSendTo,
}: Props) {
  /**
   * MailUp configurations
   */

  let mailUpList: string = '1'
  let mailUpGroup: string = '33'

  switch (languageCode) {
    case 'de-DE':
      mailUpList = '4'
      mailUpGroup = '34'
      break
    case 'en-US':
      mailUpList = '4'
      mailUpGroup = '37'
      break

    default:
      break
  }

  /*
   * Form data
   */
  const [isSendingForm, setIsSendingForm] = useState(false)
  const [displaySendErrorFeedback, setDisplaySendErrorFeedback] =
    useState(false)
  const [displaySendConfirmFeedback, setDisplaySendConfirmFeedback] =
    useState(false)

  const onValidSubmission = useCallback(
    async (data: FormData) => {
      if (isSendingForm) {
        return
      }

      setDisplaySendErrorFeedback(false)
      setDisplaySendConfirmFeedback(false)
      setIsSendingForm(true)
      const sendResult = await onNewsletterFormSendTo(data)
      setIsSendingForm(false)

      if (isLeft(sendResult)) {
        setDisplaySendErrorFeedback(true)
      } else {
        setDisplaySendConfirmFeedback(true)

        typeof window.gtag !== 'undefined' &&
          window.gtag('event', 'Submit', {
            event_category: 'Website',
            event_label: 'Newsletter Form',
          })
      }
    },
    [isSendingForm, onNewsletterFormSendTo],
  )

  const { register, formState, handleSubmit } = useForm<FormData>({
    mode: 'onBlur',
    shouldFocusError: true,
  })

  const onSubmit = useMemo(
    () => handleSubmit(onValidSubmission),
    [handleSubmit, onValidSubmission],
  )

  /**
   * Handle scrolling to first field with errors
   */
  useEffect(() => {
    if (formState.errors) {
      const firstErrorElement = Object.values(formState.errors).find(
        (fieldError) => fieldError?.ref !== undefined,
      )?.ref as HTMLElement | undefined

      if (firstErrorElement && firstErrorElement.scrollIntoView) {
        firstErrorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }
  }, [formState.errors])

  return (
    <Container>
      <Form
        onSubmit={isSendingForm ? undefined : onSubmit}
        noValidate
        autoComplete={'off'}
      >
        <Spinner className={isSendingForm ? '' : 'hidden'} />
        <Title>{useVocabularyData('newsletter-title', languageCode)}</Title>
        <Wrapper row wrap space="between">
          <Input
            type="hidden"
            value={mailUpList}
            required={true}
            {...register('list', {
              required: true,
            })}
          />
          <Input
            type="hidden"
            value={mailUpGroup}
            required={true}
            {...register('group', {
              required: true,
            })}
          />
          <Input
            type="hidden"
            value="off"
            required={true}
            {...register('confirm', {
              required: true,
            })}
          />
          <Field>
            <Input
              color="dark"
              error={formState.errors.campo1 !== undefined}
              isDirty={formState.dirtyFields.campo1}
              placeholder={`${useVocabularyData('name', languageCode)} *`}
              type="text"
              required={true}
              {...register('campo1', {
                required: true,
                validate: (campo1) => campo1.length > 2,
              })}
            />
          </Field>
          <Field>
            <Input
              color="dark"
              error={formState.errors.campo2 !== undefined}
              placeholder={`${useVocabularyData('lastname', languageCode)} *`}
              type="text"
              required={true}
              {...register('campo2', {
                required: true,
                validate: (campo2) => campo2.length > 2,
              })}
            />
          </Field>
          <Field>
            <Input
              color="dark"
              error={formState.errors.email !== undefined}
              isDirty={formState.dirtyFields.email}
              placeholder={`${useVocabularyData('email', languageCode)} *`}
              type="email"
              required={true}
              {...register('email', {
                required: true,
                validate: (email) => EMAIL_REGEX.test(email),
              })}
            />
          </Field>
          <Input
            name="submit"
            type="submit"
            value={useVocabularyData('subscribe', languageCode)}
            variant="submit"
          />
        </Wrapper>
        <Checkboxes>
          <Checkbox
            error={formState.errors.privacy_policy !== undefined}
            label={useVocabularyData('form-policy-text', languageCode)}
            isNewsletter
            required={true}
            {...register('privacy_policy', {
              required: true,
            })}
          />
        </Checkboxes>
        {displaySendErrorFeedback ? (
          <FormMessages
            text={useVocabularyData('form-error-message', languageCode)}
            title={useVocabularyData('form-error-message-title', languageCode)}
            type="error"
          />
        ) : null}
        {displaySendConfirmFeedback ? (
          <FormMessages
            text={useVocabularyData(
              'newsletter-confirmation-message',
              languageCode,
            )}
            title={useVocabularyData(
              'newsletter-confirmation-message-title',
              languageCode,
            )}
          />
        ) : null}
      </Form>
    </Container>
  )
})

const Container = styled.div`
  margin-top: 4rem;
  padding-top: 3.75rem;
  position: relative;
  &:before {
    content: '';
    width: 95.833vw;
    height: 0.0625rem;
    background: ${({ theme }) => theme.colors.variants.primaryDark};
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 1023px) {
    margin-top: 3.125rem;
    padding: 3.125rem 2rem 0;
    &:before {
      width: calc(100% - 4rem);
    }
  }
`

const Form = styled.form`
  width: 100%;
  max-width: 55.5rem;
  margin: auto;
  position: relative;

  input[type='submit'] {
    width: 6.375rem;
    min-width: auto;
    height: 3rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    border: none;
    color: ${({ theme }) => theme.colors.variants.primaryLight};
    margin: 0;
    padding: 0;
    &:hover {
      background: ${({ theme }) => theme.colors.variants.secondaryLight};
      color: ${({ theme }) => theme.colors.variants.neutralLight4};
    }
  }

  @media (max-width: 1023px) {
    text-align: left;

    input[type='submit'] {
      width: 100%;
      margin: 1.875rem 0 0;
    }
  }
`

const Wrapper = styled(FlexBox)`
  margin-top: 2rem;

  @media (max-width: 1023px) {
    display: block;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-align: center;
  text-transform: uppercase;
`

const Field = styled.div`
  flex: 1;
  margin-right: 0.125rem;

  input {
    height: 3rem;
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0 1.25rem;

    &::-webkit-input-placeholder {
      opacity: 0.4;
    }
    &::-moz-placeholder {
      opacity: 0.4;
    }
    &::-ms-input-placeholder {
      opacity: 0.4;
    }
    &::placeholder {
      opacity: 0.4;
    }
  }

  @media (max-width: 1023px) {
    margin: 0.125rem 0 0;
  }
`

const Checkboxes = styled.div`
  text-align: center;
`
