import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  memo,
} from 'react'

export interface Props {
  error?: boolean
  name: string
  isNewsletter?: boolean
  label?: string
  required?: boolean
  onBlur?: FocusEventHandler<HTMLInputElement>
  onChange?: ChangeEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
  variant?: Variant
}

export const Checkbox = memo(
  forwardRef<HTMLInputElement, Props>(function Checkbox(
    {
      error = false,
      name,
      isNewsletter,
      label,
      required,
      onBlur,
      onChange,
      onFocus,
      variant = 'default',
    },
    ref,
  ) {
    const id = `#form-${name}${isNewsletter ? '-newsletter' : ''}`

    return (
      <Container dial={5} inline row>
        <InputField
          ref={ref}
          id={id}
          name={name}
          type="checkbox"
          required={required}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
        />
        {label ? (
          <Label
            error={error}
            htmlFor={id}
            dangerouslySetInnerHTML={{ __html: label }}
            variant={variant}
          />
        ) : null}
      </Container>
    )
  }),
)
Checkbox.displayName = 'Checkbox'

const Container = styled(FlexBox)`
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  line-height: 1.0625rem;
  margin: 1.875rem auto 0;
  position: relative;
  text-align: left;
`

const InputField = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;

  &:checked ~ label {
    &:after {
      opacity: 1;
    }
  }
`

const Label = styled.label<ContainerProps>`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding-left: 1.375rem;
  &:before,
  &:after {
    content: '';
    width: 0.9375rem;
    height: 0.9375rem;
    border-radius: 50%;
    position: absolute;
    top: 0.0625rem;
    left: 0;
  }
  &:before {
    border: 0.125rem solid
      ${({ error, theme }) =>
        error
          ? theme.colors.variants.dangerLight
          : theme.colors.variants.neutralLight4};
  }
  &:after {
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    opacity: 0;
  }

  a {
    font-weight: 600;
    text-decoration: underline;
  }

  ${({ variant, theme, error }) => {
    switch (variant) {
      case 'dark':
        return css`
          color: ${theme.colors.variants.neutralDark5};
          &:before {
            border-color: ${error
              ? theme.colors.variants.dangerLight
              : theme.colors.variants.neutralDark5};
          }
          &:after {
            background: ${theme.colors.variants.neutralDark5};
          }
        `
      case 'large':
        return css`
          font-size: 0.875rem;
          padding-left: 2rem;
          &:before {
            width: 1.25rem;
            height: 1.25rem;
            border: none;
            background: ${theme.colors.variants.primaryDark};
            top: -0.0625rem;
          }
          &:after {
            width: 0.75rem;
            height: 0.75rem;
            background: ${theme.colors.variants.neutralLight4};
            left: 0.25rem;
          }
        `
    }
  }}
`

interface ContainerProps {
  error: boolean
  variant: Variant
}

export type Variant = 'default' | 'dark' | 'large'
