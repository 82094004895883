import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Minus, Plus, Rumble } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo, useState } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  benefits: ItemProps[]
  languageCode: string
  title?: string
  variant?: Variant
}

export const Benefits = memo(function Benefits({
  benefits,
  languageCode,
  title,
  variant = 'default',
}: Props) {
  if (benefits.length < 1) {
    return null
  }

  const [expanderStatus, setExpanderStatus] = useState(false)

  return (
    <Container
      dial={5}
      row
      stretch
      tag={variant === 'default' ? 'section' : 'div'}
      variant={variant}
    >
      {title ? (
        <Title className="benefits-title" dial={4} row>
          <Rumble />
          <Label>{title}</Label>
        </Title>
      ) : null}

      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Media lessThan="desktopSmall">
          <Expander
            dial={5}
            onClick={() =>
              expanderStatus
                ? setExpanderStatus(false)
                : setExpanderStatus(true)
            }
            row
          >
            {useVocabularyData('show', languageCode)}
            {expanderStatus ? <Minus /> : <Plus />}
          </Expander>
        </Media>
      </MediaContextProvider>

      <List
        className={expanderStatus ? 'open' : undefined}
        dial={5}
        row
        space="between"
      >
        {benefits.map((item, index) => (
          <Item key={index} variant={variant} {...item} />
        ))}
      </List>
    </Container>
  )
})

const Container = styled(FlexBox)<ContainerProps>`
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  padding: 0 2rem;
  &:before {
    content: '';
    width: 25%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.primaryDark};
    position: absolute;
    top: 0;
    left: 0;
  }

  ${({ theme, variant }) => {
    switch (variant) {
      case 'ibe':
        return css`
          width: 100%;
          height: 9rem;
          background: ${theme.colors.variants.primaryDark};
          text-align: left;
          &:before {
            display: none;
          }

          .benefits-title {
            margin-right: 3.681vw;
          }

          @media (max-height: 700px) {
            display: none;
          }
        `
    }
  }}

  @media (max-width: 1199px) {
    display: block;
    padding-top: 2.125rem;
    padding-bottom: 2.3125rem;
    text-align: center;
    &:before {
      display: none;
    }
    &:after {
      width: 0;
      height: 0;
      content: '';
      border-left: 1.125rem solid transparent;
      border-right: 1.125rem solid transparent;
      border-top: 1.3125rem solid
        ${({ theme }) => theme.colors.variants.primaryLight};
      position: absolute;
      bottom: -1.125rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`

const Title = styled(FlexBox)`
  max-width: 25rem;
  background: ${({ theme }) => theme.colors.variants.primaryDark};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin-right: 11.111vw;
  padding: 0 4.306vw;
  position: relative;
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 1rem solid transparent;
    border-left: 1.125rem solid
      ${({ theme }) => theme.colors.variants.primaryDark};
    border-bottom: 1rem solid transparent;
    position: absolute;
    top: 50%;
    right: -1.125rem;
    transform: translateY(-50%);
  }

  svg {
    width: 1.625rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.secondaryLight};
    margin-right: 1.25rem;
  }

  @media (max-width: 1199px) {
    display: block;
    width: 100%;
    max-width: none;
    background: none;
    margin: auto;
    padding: 0;
    &:after {
      display: none;
    }

    svg {
      display: block;
      margin: 0 auto 0.875rem;
    }
  }
`

const Label = styled.span`
  width: calc(100% - 2.875rem);

  @media (max-width: 1199px) {
    width: 100%;
  }
`

const Expander = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.075rem;
  line-height: 1.125rem;
  margin: 1.25rem auto 0;
  text-transform: uppercase;

  svg {
    width: auto;
    height: 0.75rem;
    margin-left: 0.625rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 2;
  }
`

const List = styled(FlexBox)`
  padding: 3.25rem 0;

  @media (max-width: 1199px) {
    display: none;
    padding: 0;
    &.open {
      display: block;
      margin-top: 2.25rem;
    }
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'ibe'
