import { theme } from 'app/theme'
import React, { memo } from 'react'
import { Helmet } from 'react-helmet'

export interface Props {
  languagePrefix?: string
}

export const Iubenda = memo(function Iubenda({ languagePrefix }: Props) {
  if (
    !process.env.IUBENDA_SITE_ID ||
    !process.env.IUBENDA_COOKIE_IT_ID ||
    !process.env.IUBENDA_COOKIE_EN_ID
  ) {
    return null
  }

  return (
    <Helmet>
      <script type="text/javascript">
        {` var _iub = _iub || [];
            _iub.csConfiguration = {
              "askConsentAtCookiePolicyUpdate": true,
              "countryDetection": true,
              "enableUspr": true,
              "floatingPreferencesButtonDisplay": "bottom-right",
              "invalidateConsentWithoutLog": true,
              "perPurposeConsent": true,
              "siteId": ${process.env.IUBENDA_SITE_ID},
              "whitelabel": false,
              "cookiePolicyId": ${
                languagePrefix === 'it'
                  ? process.env.IUBENDA_COOKIE_IT_ID
                  : process.env.IUBENDA_COOKIE_EN_ID
              },
              "lang": "${languagePrefix}",
              "banner": { "acceptButtonColor": "${
                theme.colors.variants.secondaryLight
              }",
              "acceptButtonDisplay": true,
              "backgroundColor": "${theme.colors.variants.primaryDark}",
              "closeButtonDisplay": false,
              "customizeButtonColor": "#002B39",
              "customizeButtonDisplay": true,
              "explicitWithdrawal": true,
              "fontSizeBody": "12px",
              "listPurposes": true,
              "position": "bottom",
              "rejectButtonColor": "${theme.colors.variants.secondaryLight}",
              "rejectButtonDisplay": true
            }
          };
        `}
      </script>

      <script
        type="text/javascript"
        src={`https://cs.iubenda.com/autoblocking/${process.env.IUBENDA_SITE_ID}.js`}
      />
      <script type="text/javascript" src="//cdn.iubenda.com/cs/gpp/stub.js" />
      <script
        type="text/javascript"
        src="//cdn.iubenda.com/cs/iubenda_cs.js"
        async
      />
    </Helmet>
  )
})
