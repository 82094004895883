import styled from '@emotion/styled'
import { Facebook, Instagram, Whatsapp } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  facebookURL?: string
  instagramURL?: string
  whatsapp?: string
}

export const Social = memo(function Social({
  facebookURL,
  instagramURL,
  whatsapp,
}: Props) {
  if (!facebookURL && !instagramURL && !whatsapp) {
    return null
  }

  return (
    <Container dial={2} row wrap>
      {facebookURL ? (
        <Anchor
          aria-label="Facebook"
          href={facebookURL}
          rel="noreferrer"
          target="_blank"
        >
          <Facebook />
        </Anchor>
      ) : null}
      {instagramURL ? (
        <Anchor
          aria-label="Instagram"
          href={instagramURL}
          rel="noreferrer"
          target="_blank"
        >
          <Instagram />
        </Anchor>
      ) : null}
      {whatsapp ? (
        <Anchor
          aria-label="WhatsApp"
          href={`https://api.whatsapp.com/send?phone=${whatsapp}`}
          rel="noreferrer"
          target="_blank"
        >
          <Whatsapp />
        </Anchor>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-top: 3.75rem;
`

const Anchor = styled.a`
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.variants.secondaryLight};
    }
  }

  svg {
    width: auto;
    height: 1.25rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin: 0 0.75rem;
    transition: 0.15s ease-in-out;
  }
`
