import styled from '@emotion/styled'
import { Calendar, Mail } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { HeaderContext } from 'app/containers/Header'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo, useContext, useEffect, useState } from 'react'

export interface Props {
  quotationPageURL?: string
}

export const Toolbars = memo(function Toolbars({ quotationPageURL }: Props) {
  const { IBEID, languageCode, languagePrefix } = useContext(
    HeaderContext,
  ) as any

  const [scrollDir, setScrollDir] = useState('up')

  useEffect(() => {
    const threshold = 0
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }
      setScrollDir(scrollY > lastScrollY ? 'down' : 'up')
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollDir])

  return (
    <Container
      className={scrollDir == 'down' ? 'hidden' : ''}
      dial={5}
      row
      stretch
    >
      {IBEID ? (
        <Item
          href={`https://be.bookingexpert.it/book/simple/step1?hotel=${IBEID}&lang=${
            languagePrefix || 'it'
          }`}
          target="_blank"
        >
          <Calendar />
          {useVocabularyData('book', languageCode)}
        </Item>
      ) : null}
      {quotationPageURL ? (
        <Link to={quotationPageURL}>
          <Mail />
          {useVocabularyData('ask-for', languageCode)}
        </Link>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 50;
  text-transform: uppercase;
  transition: 0.3s ease-out;
  &.hidden {
    transform: translateY(6rem);
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    background: ${({ theme }) => theme.colors.variants.primaryLight};
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    font-size: 0.875rem;
    letter-spacing: 0.0437rem;
    line-height: 1.3125rem;
    padding: 1.25rem 0 1.1875rem 0;
    text-transform: uppercase;

    svg {
      width: auto;
      height: 1.125rem;
      fill: none;
      margin-right: 0.75rem;
      stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
      stroke-width: 1.5;
      transform: translateY(-0.0625rem);
    }
  }
`

const Item = styled.a`
  background: ${({ theme }) => theme.colors.variants.secondaryLight} !important;

  svg {
    transform: translateY(-0.125rem);
  }
`
