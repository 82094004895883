import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  icon?: string
  label?: string
  variant?: Variant
}

export const Item = memo(function Item({
  icon,
  label,
  variant = 'default',
}: Props) {
  if (!icon || !label) {
    return null
  }

  return (
    <Container variant={variant}>
      {icon ? <Icon src={icon} alt={label} width="30" height="30" /> : null}
      {label ? <Label dangerouslySetInnerHTML={{ __html: label }} /> : null}
    </Container>
  )
})

const Container = styled.div<ContainerProps>`
  max-width: 11.875rem;
  margin: 0 5.139vw;
  position: relative;
  text-align: center;
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
    &:after {
      display: none;
    }
  }
  &:after {
    content: '';
    width: 0.0625rem;
    height: 1.875rem;
    background: ${({ theme }) => theme.colors.variants.primaryDark};
    position: absolute;
    top: 50%;
    right: -5.139vw;
    transform: translateY(-50%);
  }

  ${({ variant }) => {
    switch (variant) {
      case 'ibe':
        return css`
          display: flex;
          align-items: center;
          max-width: none;
          margin: 0 3.681vw;
          text-align: left;

          img {
            width: 1.875rem;
            max-width: none;
            height: auto;
            filter: invert(100%);
            margin: 0 1.25rem 0 0;
          }
        `
    }
  }}

  @media (max-width: 1199px) {
    max-width: none;
    margin: 1.25rem auto 0;
  }
`

const Icon = styled.img`
  width: auto;
  max-width: 2.5rem;
  height: 1.875rem;
  margin-bottom: 1.25rem;

  @media (max-width: 1199px) {
    display: none;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  line-height: 1.25rem;

  @media (max-width: 1199px) {
    br {
      display: inline;
      content: ' ';
      padding: 0 0.125rem;
    }
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'ibe'
