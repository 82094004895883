import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Calendar, Email, Gift } from 'app/components/Icons'
import { theme } from 'app/theme'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  id?: string
  icon?: 'calendar' | 'email' | 'gift'
  languageCode?: string
  languagePrefix?: string | null
  target?: string
  title: string
  url: string
  variant: Variant
}

export const BottomMenuItem = memo(function BottomMenuItem({
  icon,
  target,
  title,
  url,
  variant,
}: Props) {
  if (!url) {
    return null
  }

  const renderIcon = () => {
    switch (icon) {
      case 'calendar':
        return <Calendar />
      case 'email':
        return <Email />
      case 'gift':
        return <Gift />
    }
  }

  return (
    <>
      {target ? (
        <External variant={variant} href={url} rel="noopener" target={target}>
          {icon ? renderIcon() : null}
          {title ? title : undefined}
        </External>
      ) : (
        <Anchor variant={variant} to={url}>
          {icon ? renderIcon() : null}
          {title ? title : undefined}
        </Anchor>
      )}
    </>
  )
})

const Style = css`
  display: inline-flex;
  color: ${theme.colors.variants.neutralLight4};
  font-family: ${theme.fontFamily.heading};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin-right: 5.3125rem;
  &:hover {
    color: ${theme.colors.variants.secondaryLight};
  }
  &:last-of-type {
    margin-right: 0;
  }

  svg {
    width: auto;
    height: 1.25rem;
    margin-right: 0.75rem;
    opacity: 0.3;
    fill: none;
    stroke: ${theme.colors.variants.neutralLight4};
    stroke-width: 1.5;
  }
`
const HeaderStyle = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 3.75rem;
  color: ${theme.colors.variants.neutralLight4};
  cursor: pointer;
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.0437rem;
  line-height: 0.875rem;
  padding: 0 1.875rem;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;

  .fixed & {
    color: ${theme.colors.variants.primaryDark};

    svg {
      stroke: ${theme.colors.variants.primaryDark};
    }
  }

  &:hover {
    opacity: 0.7;
  }
  &:last-of-type {
    margin-right: 0;
  }

  svg {
    width: 0.875rem;
    height: auto;
    fill: none;
    margin-right: 0.75rem;
    stroke: ${theme.colors.variants.neutralLight4};
    stroke-width: 1.5;
  }

  @media (max-width: 1199px) {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    background: ${theme.colors.variants.neutralLight2};
    color: ${theme.colors.variants.primaryDark};
    height: 3.75rem;
    width: 3.75rem;
    padding: 0;
    margin: 0;
    font-size: 0.5rem;

    svg {
      stroke: ${theme.colors.variants.primaryDark};
      margin: 0 auto 0.3125rem;
    }
  }
`

const Anchor = styled(Link)<ContainerProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'default':
        return Style
      case 'header':
        return HeaderStyle
    }
  }}
`

const External = styled.a<ContainerProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'default':
        return Style
      case 'header':
        return HeaderStyle
    }
  }}
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'header'
