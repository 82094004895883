import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

export interface Props {
  bookingPhone?: string
  email?: string
  infoPhone?: string
  languageCode: string
  logo?: string
  logoMira?: string
  siteName?: string
}

export const Contacts = memo(function Contacts({
  bookingPhone,
  email,
  infoPhone,
  languageCode,
  logo,
  logoMira,
  siteName,
}: Props) {
  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Media greaterThanOrEqual="ipadHorizontal">
          <Title>{useVocabularyData('contacts', languageCode)}</Title>
        </Media>
        <Wrapper row space="between" wrap>
          {logo ? (
            <Logo src={logo} alt={siteName} width="250" height="86" />
          ) : null}

          <Center>
            <Media lessThan="ipadHorizontal">
              <Title>{useVocabularyData('contacts', languageCode)}</Title>
            </Media>
            {infoPhone ? (
              <Anchor
                href={`tel:${infoPhone}`}
                onClick={() => {
                  if (isMobile) {
                    typeof window.gtag !== 'undefined' &&
                      window.gtag('event', 'Click', {
                        event_category: 'Website',
                        event_label: 'Phone Number',
                      })
                  }
                }}
              >
                {`${useVocabularyData(
                  'info-booking',
                  languageCode,
                )}: ${infoPhone}`}
              </Anchor>
            ) : null}
            {email ? (
              <>
                <Divider />
                <Anchor
                  href={`mailto:${email}`}
                  onClick={() => {
                    typeof window.gtag !== 'undefined' &&
                      window.gtag('event', 'Click', {
                        event_category: 'Website',
                        event_label: 'Email Address',
                      })
                  }}
                >
                  {email}
                </Anchor>
              </>
            ) : null}
          </Center>

          <Media greaterThanOrEqual="ipadHorizontal">
            {logoMira ? (
              <LogoLink
                href="https://www.mirahotels.com"
                rel="noopener"
                target="_blank"
              >
                <Logo src={logoMira} alt={siteName} width="113" height="90" />
              </LogoLink>
            ) : null}
          </Media>
        </Wrapper>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.div`
  position: relative;

  @media (max-width: 1023px) {
    padding: 5.0625rem 2rem 0;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: 1.125rem;
  text-align: center;
  text-transform: uppercase;
`

const Wrapper = styled(FlexBox)`
  @media (max-width: 1023px) {
    display: block;
    text-align: center;
  }
`

const LogoLink = styled.a``

const Logo = styled.img`
  @media (max-width: 1023px) {
    margin: 0 auto 2.9375rem;
  }
`

const Center = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);

  @media (max-width: 1023px) {
    position: relative;
    bottom: auto;
    left: auto;
    transform: none;
  }
`

const Anchor = styled.a`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.875rem;
  &:hover {
    text-decoration: underline;
  }
`

const Divider = styled.div``
