import styled from '@emotion/styled'
import { Benefits } from 'app/components/Benefits'
import { HeaderContext } from 'app/containers/Header'
import isbot from 'isbot'
import { compact } from 'lodash'
import React, { memo, useContext } from 'react'

import { Syncrobox } from './Syncrobox'

export const ModalIBE = memo(function ModalIBE() {
  const { IBEID, languageCode, languagePrefix } = useContext(
    HeaderContext,
  ) as any

  if (typeof window !== 'undefined' && isbot(navigator.userAgent)) {
    return null
  }

  /*
   * Benefits data
   */
  const { benefits } = useContext(HeaderContext) as any

  const benefitsTranslation = benefits.translations?.find(
    (t: any) => t?.languages_code?.code === languageCode,
  )

  const benefitsTitle = benefitsTranslation?.title || undefined

  const benefitsList = compact(benefitsTranslation?.list).map(
    ({ iconset_id }: any) => {
      const translation = iconset_id?.translations?.filter(
        (t: any) => t?.languages_code?.code === languageCode,
      )[0]

      const icon = iconset_id?.icon?.file?.publicURL || undefined
      const label = translation?.label || undefined

      return {
        icon,
        label,
      }
    },
  )

  return (
    <Container>
      {IBEID ? (
        <Syncrobox
          IBEID={IBEID}
          languageCode={languageCode}
          languagePrefix={languagePrefix || 'it'}
        />
      ) : null}

      <Benefits benefits={benefitsList} title={benefitsTitle} variant="ibe" />
    </Container>
  )
})

const Container = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.primaryLight};
`
