import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { compact, uniqBy } from 'lodash'
import React, { memo } from 'react'

import { MenuItem, Props as MenuItemProps } from './MenuItem'

export interface Props {
  cin?: string
  languageCode: string
  policyMenu?: { [key: string]: MenuItemProps[] }[]
  vat?: string
}

export const Bottom = memo(function Bottom({
  cin,
  languageCode,
  policyMenu,
  vat,
}: Props) {
  const policyNav = compact(
    compact(policyMenu?.map((lang) => lang[languageCode as 'it-IT']))[0],
  ).filter((t) => t.languageCode === languageCode)

  return (
    <Container row space="between" wrap>
      <Wrap>
        {vat ? (
          <Vat>{`${useVocabularyData('vat', languageCode)} ${vat}`}</Vat>
        ) : null}

        {cin ? (
          <Cin>{`${useVocabularyData('cin', languageCode)}: ${cin}`}</Cin>
        ) : null}
      </Wrap>

      <IubendaLink
        href={`https://www.iubenda.com/privacy-policy/${
          languageCode === 'it-IT' ? 91422635 : 52970711
        }`}
        className="iubenda-white iubenda-noiframe iubenda-embed iub-legal-only iubenda-noiframe"
        title="Privacy Policy"
      >
        Privacy Policy
      </IubendaLink>

      <IubendaLink
        href={`https://www.iubenda.com/privacy-policy/${
          languageCode === 'it-IT' ? 91422635 : 52970711
        }/cookie-policy`}
        className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
        title="Cookie Policy"
      >
        Cookie Policy
      </IubendaLink>

      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);`,
        }}
      />

      <Credits
        aria-label="QNT Hospitality"
        href="https://www.qnt.it"
        rel="noopenet"
        target="_blank"
      >
        produced by <b>QNT HOSPITALITY</b>
      </Credits>
    </Container>
  )
})

const Container = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  line-height: 1.5rem;
  margin-top: 3.75rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    display: block;
    padding: 0 2rem;
  }

  .iubenda-embed:not(.no-brand):not(.iubenda-nostyle) {
    background: none !important;
    color: ${({ theme }) => theme.colors.variants.neutralLight4} !important;
    font-family: ${({ theme }) => theme.fontFamily.paragraph} !important;
    font-size: 0.75rem !important;
    font-weight: 400 !important;
    line-height: 1.5rem !important;
    margin-right: 1.25rem !important;
    padding: 0 !important;
    text-transform: uppercase !important;
    vertical-align: top !important;
    box-shadow: none !important;
  }
`

const Wrap = styled.div`
  @media (max-width: 1023px) {
    display: block;
    margin-bottom: 1rem;
  }
`

const Vat = styled.span`
  @media (max-width: 1023px) {
    display: block;
  }
`

const Cin = styled.span`
  &:before {
    content: '-';
    margin: 0 0.375rem;
  }

  @media (max-width: 1023px) {
    display: block;
    &:before {
      display: none;
    }
  }
`

const Credits = styled.a`
  b {
    font-weight: 600;
  }
`

const IubendaLink = styled.a`
  @media (max-width: 1023px) {
    display: block;
  }
`
