import styled from '@emotion/styled'
import { ChevronDown } from 'app/components/Icons'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  className?: string
  code: string
  pageID?: string
  languagePrefix: string | null
  URL: string
}

export const LanguageItem = memo(function LanguageItem({
  className,
  code,
  languagePrefix,
  URL,
}: Props) {
  return (
    <Anchor
      className={className}
      to={URL}
      lang={languagePrefix === code ? undefined : code}
    >
      <Label>{code}</Label>
      <ChevronDown />
    </Anchor>
  )
})

const Anchor = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 600;
  letter-spacing: 0.1406rem;
  line-height: 1.25rem;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  &:hover {
    text-decoration: underline;
  }
  &.active {
    background: none;
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
    pointer-events: none;

    svg {
      display: block;
      transition: 0.3s ease-in-out;
    }
  }

  svg {
    display: none;
    width: auto;
    height: 0.75rem;
    margin-left: 0.625rem;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 1.5;
  }
`

const Label = styled.span``
