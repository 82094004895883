import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Minus, Plus } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme/index'
import { AnimatePresence, motion } from 'framer-motion'
import { Link } from 'gatsby'
import React, { memo, useEffect, useState } from 'react'

export interface Props {
  childs?: ChildsProps[]
  className?: string
  id?: string
  title: string
  languageCode?: string
  languagePrefix?: string | null
  modalStatus?: boolean
  url: string
}

interface ChildsProps {
  className?: string
  id?: string
  isHighlighted?: boolean
  modalStatus?: boolean
  title: string
  url: string
}

export const MenuItem = memo(function MenuItem({
  childs,
  className,
  modalStatus,
  title,
  url,
}: Props) {
  if (!title) {
    return null
  }

  const [submenuVisibility, setSubmenuVisibility] = useState(false)

  useEffect(() => {
    setSubmenuVisibility(false)
  }, [])

  return (
    <>
      {url ? (
        <Anchor className={className} to={url} activeClassName="active">
          {title}
        </Anchor>
      ) : (
        <NoLink
          dial={4}
          className={submenuVisibility ? 'open' : undefined}
          inline
          onClick={() =>
            submenuVisibility
              ? setSubmenuVisibility(false)
              : setSubmenuVisibility(true)
          }
          row
          tag="span"
        >
          {title}
          {submenuVisibility ? <Minus /> : <Plus />}
        </NoLink>
      )}

      {childs && childs.length > 0 ? (
        <AnimatePresence>
          {submenuVisibility && (
            <Submenu
              initial={{ opacity: 0, height: 0 }}
              animate={{
                opacity: 1,
                height: 'auto',
              }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              {childs
                .sort((a: any, b: any) => a.weight - b.weight)
                .map((item, index) => (
                  <ChildItem key={index} modalStatus={modalStatus} {...item} />
                ))}
            </Submenu>
          )}
        </AnimatePresence>
      ) : null}
    </>
  )
})

export const ChildItem = memo(function ChildItem({
  isHighlighted,
  title,
  url,
}: ChildsProps) {
  return (
    <SubmenuLi>
      <Anchor
        className={isHighlighted ? 'highlighted' : undefined}
        to={url}
        activeClassName="active"
      >
        <Inner>{title}</Inner>
      </Anchor>
    </SubmenuLi>
  )
})

const Style = css`
  display: inline-block;
  color: ${theme.colors.variants.neutralLight4};
  font-family: ${theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75rem;
  text-transform: uppercase;
  transition: 0.2s ease-out;
  &.active {
    opacity: 0.4;
    pointer-events: none;
  }
  &:hover {
    color: ${theme.colors.variants.secondaryLight};
  }

  @media (max-width: 1199px) {
    font-weight: 400;
  }
`

const Anchor = styled(Link)`
  ${Style}
`

const Inner = styled.span`
  position: relative;
`

const NoLink = styled(FlexBox)`
  cursor: pointer;
  &.open {
    color: ${({ theme }) => theme.colors.variants.secondaryLight};

    svg {
      opacity: 1;
      stroke: ${({ theme }) => theme.colors.variants.secondaryLight};
    }
  }

  svg {
    width: 0.75rem;
    height: auto;
    fill: none;
    opacity: 0.4;
    margin-left: 1rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 2;
    transform: translateY(-0.125rem);
  }

  ${Style}
`

const Submenu = styled(motion.ul)`
  display: flex;

  @media (max-width: 1199px) {
    flex-wrap: wrap;
    position: relative;
    transform: translateY(0.875rem);
    &:before {
      content: '';
      width: calc(100% + 8.5rem);
      height: 100%;
      background: ${({ theme }) => theme.colors.variants.primaryLight};
      position: absolute;
      top: 0;
      left: -4.25rem;
    }
  }
`

const SubmenuLi = styled.li`
  width: 50%;
  margin-top: 1rem !important;

  a {
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.3125rem;
    opacity: 0.6;
    text-transform: none;
    &.active,
    &:hover {
      opacity: 1;
    }
    &.highlighted {
      opacity: 1;
      position: relative;
      &:hover {
        color: ${({ theme }) => theme.colors.variants.neutralLight4};
      }
      &:before {
        content: '';
        width: calc(100% + 0.5625rem);
        height: calc(100% + 0.25rem);
        background: ${({ theme }) => theme.colors.variants.dangerLight};
        position: absolute;
        top: -0.125rem;
        left: -0.25rem;
      }
    }
  }

  @media (max-width: 1199px) {
    width: 100%;
    &:first-of-type {
      margin-top: 2.5rem !important;
    }
    &:last-of-type {
      margin-bottom: 2.5rem !important;
    }

    a {
      font-size: 1rem;
      line-height: 1.5rem;
      opacity: 1;
      &.active {
        opacity: 0.6;
      }
    }
  }
`
