import axios, { AxiosError } from 'axios'
import { Either, left, right } from 'fp-ts/Either'

export interface FormData {
  list: string
  group: string
  confirm: string
  campo1: string
  campo2: string
  email: string
  privacy_policy: boolean
}

export interface NewsletterFormSenderConfiguration {
  formURL: string
}

export type NewsletterFormSender = (
  data: FormData,
) => Promise<Either<AxiosError, void>>

export const NewsletterFormSender =
  ({ formURL }: NewsletterFormSenderConfiguration): NewsletterFormSender =>
  async (data): Promise<Either<AxiosError, void>> => {
    const formData = new FormData()
    formData.append('list', data.list)
    formData.append('group', data.group)
    formData.append('confirm', data.confirm)
    formData.append('campo1', data.campo1)
    formData.append('campo2', data.campo2)
    formData.append('email', data.email)

    try {
      const response = await axios({
        method: 'POST',
        url: formURL,
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
        data: formData,
      })
      return right(undefined)
    } catch (error) {
      return left(error as any)
    }
  }
